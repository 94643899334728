const $header = document.querySelectorAll( '.js-header' );

$header.forEach( function( element, index ) {
	window.addEventListener( 'scroll', function() {
		let scrollPosition = window.scrollY;

		if ( scrollPosition >= 200 ) {
			$header[ index ].classList.add( 'c-header--has-background' );
		} else {
			$header[ index ].classList.remove( 'c-header--has-background' );
		}
	} );
} );
